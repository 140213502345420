import React from "react"
import FotoRimac from "@components/pageCasosdeExito/image/imgPhotoEmpresa/ibr/FotoIbr.png"

//logo de empresas clientes
import Ibr from "@components/pageCasosdeExito/image/logoscustomer/Ibr.svg"

const textintro = (
  <span className="">
    <span className="text-underlined-rimac">
      “IBR Perú es parte del grupo IBR Latam,
      <span className="strong-intro-casos">
          empresa líder en Contact Center y BPO.
        </span>
      En su operación peruana brindamos nuestros procesos de validación de
      teléfonos en
      <span className="strong-intro-casos"> Beex, logrando  mejorar la contactabilidad y
        los resultados de su operación.”
      </span>
    </span>
    <br />
    <span className="title-ibr">
      “IBR Perú es parte del grupo IBR Latam,
      <span className="strong-intro-casos">
        {" "}
        empresa líder en Contact Center y BPO.{" "}
      </span>{" "}
      En su operación peruana brindamos nuestros procesos de validación de
      teléfonos en
      <span className="strong-intro-casos">
        {" "}
        Beex, logrando mejorar la contactabilidad y los resultados de su
        operación.”
      </span>
    </span>
  </span>
)

const text = (
  <span>
    IBR Perú es una empresa presente en 3 países Latinoamericanos que ofrece
    servicios financieros, especialistas en cobranzas, ventas, fidelización y
    backoffice.
    <br />
    <br /> Venimos trabajando con IBR Perú aproximadamente 2 años, en los cuáles
    nuestros servicios de validación de teléfonos a través de nuestra plataforma
    Beex ha optimizado el contacto efectivo en sus carteras extrajudiciales,
    maximizando su contactibilidad y mejorando el tiempo hablado de sus
    teleoperadores.
  </span>
)

const data = {
  intro: {
    image: Ibr,
    textbody: textintro,
    classlogo: "customerIbr",
  },
  description: {
    textbody: text,
    photo: FotoRimac,
    class:"section-description"
  },
}

export default data
