import React from "react"
import { Link } from "gatsby"
const IntroPageCustomer = ({ data, location }) => {
  return (
    <section className="background-introcusatomer">
      <div className="btn-return returncasos">
        <Link to={`/casos-de-exito/${location.search}`} className="link-to-return" aria-label="enlace">
          <span className="flecha-izquierda">
            <svg
              width="16"
              height="10"
              viewBox="0 0 16 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 5L1 5M1 5L5.09091 9M1 5L5.09091 0.999999"
                stroke="#2C5C82"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          Regresar
        </Link>
      </div>
      <div className={data.classlogo}>
        <img loading="lazy" src={data.image} alt="cliente" />
        <p className={`text-introcustomer ${data.classrimac}`}>
          {data.textbody}
        </p>
      </div>
    </section>
  )
}

export default IntroPageCustomer
